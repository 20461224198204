import * as React from 'react';
import {useState} from 'react';
//Styles
// import animate from "../external-styles/bootstrap/animate.min.css";
// import boostrap from "../external-styles/bootstrap/bootstrap.css";
// import bootstrapThem from "../external-styles/bootstrap/bootstrap-theme.css";
// import custom from "../external-styles/bootstrap/custom.css";

// Images
// @ts-ignore
import logoInnovationSpreis from '../images/logo-innovationspreis.png';
// @ts-ignore
import twoPfeile from '../images/2-Pfeile.jpg';
// @ts-ignore
import threePfeile from '../images/3-Pfeile.jpg';
// @ts-ignore
import stoerer2021 from '../images/Stoerer_2021.jpg';
// @ts-ignore
import logoBpi from '../images/bpi-logo.png';
// @ts-ignore
import landDerIdeen from '../images/land-der-ideen.jpg';
// @ts-ignore
import jensSpahn from '../images/Jens-Spahn.jpg';
// @ts-ignore
import monikaSchnitzer from '../images/monika-schnitzer.jpg';
// @ts-ignore
import ernstProst from '../images/Ernst-Prost.jpg';
// @ts-ignore
import inaRemmers from '../images/Ina-Remmers.jpg';
// @ts-ignore
import victoriaOssadnik from '../images/victoria-ossadnik.jpg';
// @ts-ignore
import wolfgangJenewein from '../images/Wolfgang-Jenewein.jpg';
// @ts-ignore
import matthiasMey from '../images/Matthias-Mey.jpg';
// @ts-ignore
import valentinStalf from '../images/valentin-stalf.jpg';

const Landingpage = () => {
    const[showVideo, setShowVideo,] = useState(false);

    // const video_OnClick = () => {
    //     let video = document.getElementById('talk-video');
    //     let playing = !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2);
    //     if(!playing) {
    //         window.setTimeout(function() {
    //             video.play();
    //         }, 25);
    //     }
    // }

    return(
        <>
            {/*<Helmet>*/}
            {/*    <link rel="stylesheet" href="/bootstrap/animate.min.css" />*/}
            {/*    <link rel="stylesheet" href="/bootstrap/bootstrap.css" />*/}
            {/*    <link rel="stylesheet" href="/bootstrap/bootstrap-theme.css" />*/}
            {/*    <link rel="stylesheet" href="/bootstrap/custom.css" />*/}
            {/*</Helmet>*/}
            {/* Header */}
            <header>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-8 col-lg-8">
                            <img
                                src={logoInnovationSpreis}
                                className="img-responsive"
                                alt=""/>
                        </div>
                        <div className="col-sm-4 col-lg-4" />
                    </div>
                </div>
            </header>

            {/* Intro */}
            <section id="intro">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-4 col-lg-4 hidden-xs">
                            <img
                                src={threePfeile}
                                className="img-responsive"
                                alt=""/>
                            <img
                                src={stoerer2021}
                                className="img-responsive"
                                alt=""/>
                        </div>

                        <div className="col-sm-8 col-lg-8">
                            <h1>Wir suchen die Innovation des Jahres 2021</h1>
                            <p>
                                Auch in diesem Jahr honorieren wir herausragende und zukunftsweisende Unternehmen und Gründer,
                                die den Markt mit ihrem Produkt oder ihrer Dienstleistung verändern und einen Mehrwert für die
                                Gesellschaft schaffen.
                            </p>
                            <p>
                                Der Innovationspreis ist mit einem Burda Media&shy;volumen in Höhe von 1 Mio. EURO dotiert.
                                Zusätzlich begleitet FOCUS den Gewinner redaktionell und berichtet über die weitere Entwicklung
                                der Inno&shy;vation. Auch die Top 10 Bewerber werden exklusiv im FOCUS Magazin vorgestellt und
                                porträtiert.
                            </p>
                            <p>
                                Die Top 5 Bewerber bekommen zusätzlich die Möglichkeit, sich am Abend unseres Champions-Dinners
                                zu präsentieren. Im Anschluss wird unser Gewinner des Innovationspreises 2021 geehrt. Das
                                Champions-Dinner findet am Tag der „Munich transition for tomorrow“-Nachhaltigkeitskonferenz, am
                                Montag, den 25. Oktober 2021, statt.
                            </p>
                            <p>
                                Unsere Teilnahmebedingungen und vollständigen Bewertungskriterien
                                <span className="text-nowrap">finden Sie&nbsp;
                                    <a
                                        href="https://bewerbung.focus-innovationspreis.de/teilnahmebedingungen"
                                        target="_blank">hier</a>.
                                </span>
                            </p>
                            <img
                                src="img/Stoerer_2021.jpg?1621604068"
                                className="img-responsive visible-xs"
                                style={{paddingRight: '50px', paddingLeft: '50px', paddingTop: '25px',}}
                                alt=""
                            />
                        </div>
                    </div>

                    <div
                        id="impressionen-beginn"
                        className="row">
                        <div className="col-sm-12 col-lg-12">
                            <h1>Impressionen des Innovationspreises 2020</h1>
                            <p style={{marginBottom: '0',}}>
                                Europas führende App zur Lebensmittel&shy;rettung „Too Good To Go“ hat sich bei unserer
                                achtköpfigen Jury gegen 500 Mitbewerber durchsetzen können und erhält den, mit einem Werbebudget
                                von 1 Mio. EURO dotierten, FOCUS Innovations&shy;preis 2020.
                            </p>

                            {/* This div open the impressionen and video */}
                            <div
                                onClick={() => {
                                    return setShowVideo(!showVideo);
                                }}
                                className="pfeilrunter"
                            >
                                <a
                                    data-toggle="collapse"
                                    role="button"
                                    className="collapsed"
                                    data-target="#collapseImpressionen"
                                    aria-expanded="false"
                                    aria-controls="collapseImpressionen"
                                >
                                    {showVideo ? (
                                        <span className="show-more">
                                            <i className="fa fa-chevron-circle-down"></i>
                                            weniger anzeigen
                                        </span>
                                    ) : (
                                        <span className="show-less">
                                            <i className="fa fa-chevron-circle-up"></i>
                                          mehr anzeigen
                                        </span>
                                    )}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* impressionen, video and jury */}
            {showVideo ? (
                <div
                    id="collapseImpressionen"
                    className="collapse">
                    <div id="impressionen">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12 col-lg-12">
                                    <p>Wir haben Corona-konform zur Preis&shy;vergabe in Form einer digitalen Talkrunde geladen und
                                        gemeinsam mit Laure Berment, Deutschland-Chefin von „To Good To Go“, Ina Remmers,
                                        Mit-Gründerin des Start-ups nebenan.de, Textilunternehmer Matthias Mey und Peter Baumann,
                                        Marketing-Chef von Liqui Moly, Partner des Innovationspreises 2020, über die „Macht der
                                        Innovation“ gesprochen. Unser Chef&shy;redakteur Robert Schneider führte gemeinsam mit
                                        Professorin Dr. Monika Schnitzer (LMU München & Mitglied Wirtschaftsweisen) durch das
                                        Gespräch. Das Gespräch in voller Länge können Sie in unten stehendem Video verfolgen.</p>

                                    <div
                                        className="talk-video-container"
                                        style={{position: 'relative', overflow: 'hidden',}}
                                        // TODO: fix the video_OnClick function
                                        // onTouchEnd={video_OnClick()}
                                        // onClick={video_OnClick()}
                                    >
                                        <video
                                            id="talk-video"
                                            poster="img/play.png?1621604068"
                                            preload="none"
                                            controls
                                        >
                                            <source
                                                src="video/210415_focus_innovationspreis_converted_faststart.mp4"
                                                type="video/mp4"/>
                                            <source
                                                src="video/210415_focus_innovationspreis_converted.ogv"
                                                type="video/ogg"/>
                                        </video>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Jury section Anfang */}
                    <section id="jury">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 text-center jury-kopf">
                                    <h2>Jury 2020</h2>

                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xs-6 col-md-3 text-center jurycard">
                                    <img
                                        src={jensSpahn}
                                        className="img-responsive img-circle"
                                        alt="Jens Spahn"/>
                                    <h2>Jens Spahn</h2>
                                    <p>Bundesgesundheitsminister, CDU. Managt seit Monaten den Kampf gegen Corona – und verantwortet
                                        den Neustart des Landes mit</p>
                                </div>

                                <div className="col-xs-6 col-md-3 text-center jurycard">
                                    <img
                                        src={monikaSchnitzer}
                                        className="img-responsive img-circle"
                                        alt="Monika Schnitzer"/>
                                    <h2>Monika Schnitzer</h2>
                                    <p>Professorin. Lehrt Volkswirtschaft in München. Mitglied des Rates der Wirtschaftsweisen.
                                        Betont die ökonomische Kraft von Innovationen</p>
                                </div>

                                <div className="col-xs-6 col-md-3 text-center jurycard">
                                    <img
                                        src={ernstProst}
                                        className="img-responsive img-circle"
                                        alt="Ernst Prost"/>
                                    <h2>Ernst Prost</h2>
                                    <p>Geschäftsführer der weltweit tätigen Liqui Moly GmbH (Schmierstoffe, Motoröle, Additive).
                                        Sieht die Krise als Chance</p>
                                </div>

                                <div className="col-xs-6 col-md-3 text-center jurycard">
                                    <img
                                        src={inaRemmers}
                                        className="img-responsive img-circle"
                                        alt="Ina Remmers"/>
                                    <h2>Ina Remmers</h2>
                                    <p>Mitgründerin und Geschäftsführerin des innovativen und erfolgreichen Nachbarschafts-Netzwerks
                                        nebenan.de</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xs-6 col-md-3 text-center jurycard">
                                    <img
                                        src={victoriaOssadnik}
                                        className="img-responsive img-circle"
                                        alt="Victoria Ossadnik"/>
                                    <h2>Victoria Ossadnik</h2>
                                    <p>Vorsitzende der Geschäftsführung von E.ON Deutschland. Das Unternehmen fördert und kooperiert
                                        mit Start-ups</p>
                                </div>

                                <div className="col-xs-6 col-md-3 text-center jurycard">
                                    <img
                                        src={wolfgangJenewein}
                                        className="img-responsive img-circle"
                                        alt="Wolfgang Jenewein"/>
                                    <h2>Wolfgang Jenewein</h2>
                                    <p>Professor. Lehrt Betriebswirtschaft in St. Gallen. Forscht zur Durchführung von
                                        Change-Prozessen in Unternehmen. Coacht Top-Manager</p>
                                </div>

                                <div className="col-xs-6 col-md-3 text-center jurycard">
                                    <img
                                        src={matthiasMey}
                                        className="img-responsive img-circle"
                                        alt="Matthias Mey"/>
                                    <h2>Matthias Mey</h2>
                                    <p>Geschäftsführer der deutschen Textilfirma Mey. Das Familienunternehmen setzt auf
                                        Nachhaltigkeit und Innovationsgeist</p>
                                </div>

                                <div className="col-xs-6 col-md-3 text-center jurycard">
                                    <img
                                        src={valentinStalf}
                                        className="img-responsive img-circle"
                                        alt="Valentin Stalf"/>
                                    <h2>Valentin Stalf</h2>
                                    <p>Mitgründer und Chef von N26, einer der am schnellsten wachsenden Smartphone-Banken Europas,
                                        revolutionierte die Branche</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            ) : null}

            {/* Zitat */}
            <section id="zitat">
                <div className="container">
                    <div className="row zitat-row">
                        <div className="col-sm-3 hidden-xs">
                            <img
                                src={twoPfeile}
                                className="img-responsive zweipfeile"
                                alt=""
                            />
                        </div>
                        <div className="col-sm-9">
                            <h1>Wer morgen bestehen will,<br></br>muss heute vorangehen.</h1>
                            <p>Robert Schneider, Chefredakteur FOCUS</p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Footer */}
            <div className="footer">
                <div className="container">
                    <div className="flex-container">
                        <div className="spalte1">
                            <img
                                src={logoBpi}
                                className="img-responsive logo-spalte1"
                                alt="BPI"/>
                        </div>
                        <div className="spalte2" />
                        <div className="spalte3">
                            <img
                                src={landDerIdeen}
                                className="img-responsive logo-spalte3"
                                alt="Land der Ideen"/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Landingpage;
